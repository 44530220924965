export const AWAY_MODE_CHANGE = 'AWAY_MODE_CHANGE';
export const USER_CAPACITY_LIMIT_V2_UPDATE = 'USER_CAPACITY_LIMIT_V2_UPDATE';

// Capacity Category constants
export const ASYNC_COMMUNICATION = 'ASYNC_COMMUNICATION';
export const CALLING = 'CALLING';
export const REAL_TIME_MESSAGING = 'REAL_TIME_MESSAGING';

// Capacity Config Type constants
export const TOTAL = 'TOTAL';
export const BY_CAPACITY_CATEGORY = 'BY_CAPACITY_CATEGORY';