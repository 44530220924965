import { gate } from 'hub-http/gates';
import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
import { isAppObjectTypeId } from 'customer-data-objects/crmObject/isAppObjectTypeId';
import { useUserInfoContext } from '../../common/context/internal/UserInfoContext';
import { useCrmLinksContext } from '../../common/context/internal/CrmLinksContext';
import { FetchStatuses, isResolved, isRejected } from '../../common/types/FetchStatus';

/**
 * Fetches `crmPageEditor` FAS data and determines whether the page editor is available for the
 * location and objectTypeId provided. Note: there are some locations that aren't directly customizable but
 * are indirectly customizable. This hook will return false for those locations.
 *
 * Example: A user can customize the middle column (CRM_RECORD_MIDDLE) indirectly via the full record page
 * editor (CRM_RECORD_PAGE). This hook returns false for CRM_RECORD_MIDDLE but true for CRM_RECORD_PAGE.
 *
 *
 * @param objectTypeId
 * @param location
 * @returns True if object type and location combination are available in the page editor.
 */
export const useObjectTypeSupportsCustomization = (objectTypeId, location) => {
  const allAppSettingsState = useCrmLinksContext();
  const userInfo = useUserInfoContext();
  if (isPortalSpecificObjectType(objectTypeId)) {
    return {
      status: FetchStatuses.RESOLVED,
      data: true
    };
  }
  if (isAppObjectTypeId(objectTypeId)) {
    return {
      status: FetchStatuses.RESOLVED,
      data: true
    };
  }
  if (!userInfo) {
    return {
      status: FetchStatuses.LOADING,
      data: null
    };
  }
  const {
    gates: userGates,
    user: {
      scopes: userScopes
    }
  } = userInfo;
  if (isResolved(allAppSettingsState)) {
    const appSettings = allAppSettingsState.data.get(objectTypeId);
    const enabledLocation = appSettings === null || appSettings === void 0 ? void 0 : appSettings.crmPageEditor.metadata.enabledLocations.value.find(enabledLocationMetadata => enabledLocationMetadata.location === location);
    if (!enabledLocation) {
      return {
        status: FetchStatuses.RESOLVED,
        data: false
      };
    }
    const {
      gates: locationGates,
      scopes: locationScopes
    } = enabledLocation;
    const isUngatedForLocation = !locationGates || locationGates.every(locationGate => userGates.includes(locationGate) ||
    // eslint-disable-next-line hubspot-dev/no-bare-gate-strings
    userGates.includes(gate(locationGate)));
    const isScopedForLocation = !locationScopes || locationScopes.every(scope => userScopes.includes(scope));
    const canCustomizeObjectType = isUngatedForLocation && isScopedForLocation;
    return {
      status: FetchStatuses.RESOLVED,
      data: canCustomizeObjectType
    };
  }
  if (isRejected(allAppSettingsState)) {
    return {
      status: FetchStatuses.REJECTED,
      data: null,
      error: allAppSettingsState.error
    };
  }
  return {
    status: allAppSettingsState.status,
    data: null
  };
};