import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { convertRPCHumanToAssignee } from '../operators/assignees';
import { fetchAssigneeQuery } from '../api/queries/assignee.queries';
export const useAssignee = ({
  objectTypeId,
  userId,
  inboxId,
  skip
}) => {
  const {
    data,
    loading,
    error
  } = useQuery(fetchAssigneeQuery, {
    variables: {
      objectTypeId,
      userId,
      inboxId
    },
    skip
  });
  const assignee = useMemo(() => data && convertRPCHumanToAssignee(data.assigneeRpc.assignee), [data]);
  return {
    assignee,
    assigneeRpc: data === null || data === void 0 ? void 0 : data.assigneeRpc.assignee,
    loading,
    error
  };
};