import { useCallback, useState, useEffect } from 'react';
import { setVid, setAttachments, setEditorState
// @ts-expect-error module not typed
} from 'conversations-thread-data/common-reply/public/operators/commonReplySetters';
import { getAttachments, getReplyId, getVid, getCommonMessage } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { CommonReplyRecord } from 'conversations-thread-data/common-reply/public/records/CommonReply';
import { usePersistedCommonReplyDraft } from './usePersistedCommonReplyDraft';
import { getGenericChannelId } from 'conversations-message-history/common-message-format/operators/commonMessageGetters';
import { COMMENTS_GENERIC_CHANNEL_ID } from 'conversations-message-history/common-message-format/constants/genericChannelIds';
import { editorDraftHasAnyContent } from '../../../editor-state/protected/operators';
const defaultConflictResolver = (currentReply, __incomingEmptyReply) => currentReply;

/**
 * @description provides email reply state constructed from
 * the thread details and history, resolving metadata conflicts
 * that arise as a result of new messages appearing in the history
 * (email replies should always be with respect to the most recent
 * messsage in the history). Saves changes to email replies as
 * drafts.
 */

export const usePersistedCommonReply = ({
  emptyReply,
  onDraftChange,
  reconcileReplies = defaultConflictResolver,
  saveDebounceTime = 200,
  idType,
  idNumber
}) => {
  const vid = getVid(emptyReply);
  const genericChannelId = getGenericChannelId(getCommonMessage(emptyReply));
  const [draftReply, saveDraft, clearDraft, loadedPersistentDrafts, draftSaveFailed] = usePersistedCommonReplyDraft(idType === 'WORKSPACE_ID' ? genericChannelId : genericChannelId !== null && genericChannelId !== void 0 ? genericChannelId : COMMENTS_GENERIC_CHANNEL_ID, idType !== null && idType !== void 0 ? idType : 'THREAD_ID', Number(idNumber), saveDebounceTime, onDraftChange);
  const draftReplyWithVid = draftReply ? setVid(vid, draftReply) : draftReply;
  const [reply, setReplyState] = useState(draftReplyWithVid || emptyReply);
  const replyId = getReplyId(reply);
  const [hasReconciledForCurrentChannel, setHasReconciledForCurrentChannel] = useState(false);
  useEffect(() => {
    if (idType !== 'WORKSPACE_ID') {
      setHasReconciledForCurrentChannel(false);
    }
  }, [genericChannelId, idType, idNumber, replyId]);
  useEffect(() => {
    setReplyState(currentReply => {
      const emptyReplyId = getReplyId(emptyReply);
      const currentReplyId = getReplyId(currentReply);
      const draftReplyId = getReplyId(draftReplyWithVid);
      const hasSameGenericChannelId = draftReplyWithVid && getGenericChannelId(getCommonMessage(draftReplyWithVid)) === genericChannelId;
      if (emptyReplyId === currentReplyId) {
        // If the draft has different content (whether the editorState content size is different or the attachments are different)
        const draftHasDifferentContent = loadedPersistentDrafts && draftReplyWithVid && (draftReplyWithVid.editorState.doc.content.size !== currentReply.editorState.doc.content.size || draftReplyWithVid.attachments.size !== currentReply.attachments.size);
        if (draftHasDifferentContent && !hasReconciledForCurrentChannel) {
          setHasReconciledForCurrentChannel(true);
          if (draftReplyId === emptyReplyId) {
            return reconcileReplies(draftReplyWithVid, emptyReply);
          } else {
            const newAttachments = getAttachments(draftReplyWithVid);
            return setEditorState(draftReplyWithVid.editorState, setAttachments(newAttachments, emptyReply));
          }
        }
        return reconcileReplies(currentReply, emptyReply);
      }
      if (hasSameGenericChannelId && draftReplyId === emptyReplyId) {
        return reconcileReplies(draftReplyWithVid, emptyReply);
      }
      return emptyReply;
    });
  }, [emptyReply, draftReplyWithVid, reconcileReplies, genericChannelId, loadedPersistentDrafts, hasReconciledForCurrentChannel]);

  // This useEffect handles the case of loading attachments when forwarding
  // existing email which have attachments. Attachments are being resolved
  // in useEmptyReply.
  useEffect(() => {
    setReplyState(currentReply => {
      const currentAttachments = getAttachments(currentReply);
      const newAttachments = getAttachments(emptyReply);
      const currentReplyId = getReplyId(currentReply);
      const newReplyId = getReplyId(emptyReply);
      if (currentReplyId === newReplyId && currentAttachments.size < newAttachments.size) {
        return setAttachments(newAttachments, currentReply);
      }
      return currentReply;
    });
  }, [emptyReply]);
  useEffect(() => {
    if (vid !== getVid(reply)) {
      setReplyState(setVid(vid, reply));
    }
  }, [reply, setReplyState, vid]);
  const setReply = useCallback(replyOrSetter => {
    if (typeof replyOrSetter === 'function') {
      setReplyState(currentReply => {
        const nextReply = replyOrSetter(currentReply);
        saveDraft(nextReply);
        return nextReply;
      });
    } else if (replyOrSetter instanceof CommonReplyRecord) {
      // Check for correct CommonReplyRecord type or other onChange events bubble up and get set
      setReplyState(replyOrSetter);
      saveDraft(replyOrSetter);
    }
  }, [saveDraft, setReplyState]);
  const resetReply = useCallback(() => {
    setReplyState(emptyReply);
    clearDraft();
  }, [setReplyState, clearDraft, emptyReply]);
  return {
    replyHandlers: [reply, setReply, resetReply],
    loadedPersistentDrafts,
    hasDraftContent: Boolean(draftReply && editorDraftHasAnyContent(draftReply.editorState)),
    saveDraft,
    clearDraft,
    draftSaveFailed
  };
};