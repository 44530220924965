import debounce from 'hs-lodash/debounce';
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useMutation } from 'data-fetching-client';
import { UPSERT_DRAFT_MUTATION, DELETE_DRAFT_MUTATION } from '../../../../__generated__/chirp/com/hubspot/cv/threads/drafts/rpc/DraftsFrontendService.dfc';
import { deserializeCommonReplyDraft } from '../operators/deserializeCommonReplyDraft';
import { reportError } from 'conversations-error-reporting/error-reporting/reportError';
import { useSelector } from 'react-redux';
import { getIsUngatedForPersistedDrafts } from 'conversations-thread-data/auth/public/selectors';
import { serializeCommonReplyDraft } from '../operators/serializeCommonReplyDraft';
import { getEditorState } from 'conversations-thread-data/common-reply/public/operators/commonReplyGetters';
import { convertToPlaintext } from 'hubspot-prosemirror/prosemirror/ProsemirrorUtils';
import { usePersistentDraftsQuery } from './usePersistentDraftsQuery';
export const usePersistedCommonReplyDraft = (genericChannelId, idType, idNumber, saveTimeout, onDraftChange) => {
  const isUngatedForPersistedDrafts = useSelector(getIsUngatedForPersistedDrafts);
  const {
    loadingCurrentDrafts,
    currentDrafts,
    refetch
  } = usePersistentDraftsQuery({
    idType,
    idNumber,
    isUngatedForPersistedDrafts
  });
  useEffect(() => {
    if (isUngatedForPersistedDrafts) {
      // eslint-disable-next-line promise/catch-or-return, @typescript-eslint/no-floating-promises
      refetch();
    }
  }, [genericChannelId, refetch, isUngatedForPersistedDrafts, idNumber]);
  const [draftSaveFailed, setDraftSaveFailed] = useState(false);
  const [localDraft, setLocalDraft] = useState();
  const [saveDraft] = useMutation(UPSERT_DRAFT_MUTATION);
  const [deleteDraft] = useMutation(DELETE_DRAFT_MUTATION);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSave = useCallback(debounce((replyToSave, retryCount = 0, maxRetries = 3) => {
    if (!loadingCurrentDrafts && genericChannelId) {
      var _replyToSave$commonMe;
      if (idType === 'WORKSPACE_ID' && !((_replyToSave$commonMe = replyToSave.commonMessage) !== null && _replyToSave$commonMe !== void 0 && _replyToSave$commonMe.recipients.size)) {
        return;
      }
      const editorState = getEditorState(replyToSave);
      saveDraft({
        variables: {
          idType,
          id: idNumber,
          genericChannelId,
          jsonBlob: serializeCommonReplyDraft(replyToSave),
          timestamp: Date.now(),
          plainTextContent: convertToPlaintext(editorState),
          attachments: replyToSave.commonMessage ? replyToSave.commonMessage.attachments.toJS().map(attachment => Object.assign({}, attachment, {
            __typename: 'com.hubspot.cv.pubsub.core.message.common.Files'
          })) : []
        }
      }).then(() => {
        onDraftChange === null || onDraftChange === void 0 || onDraftChange({
          text: convertToPlaintext(editorState)
        });
        setDraftSaveFailed(false);
      }).catch(error => {
        setDraftSaveFailed(true);
        if (retryCount < maxRetries) {
          // Retry with exponential backoff
          setTimeout(() => {
            debouncedSave(replyToSave, retryCount + 1, maxRetries);
          }, 1000 * Math.pow(2, retryCount));
        } else {
          reportError({
            error: new Error('Error saving draft after max retries'),
            extra: {
              error
            }
          });
        }
      });
    }
  }, saveTimeout), [saveTimeout, genericChannelId, idNumber, loadingCurrentDrafts, saveDraft, idType]);
  useEffect(() => {
    debouncedSave.flush();
    return debouncedSave.flush;
  }, [debouncedSave]);
  useEffect(() => {
    const drafts = currentDrafts === null || currentDrafts === void 0 ? void 0 : currentDrafts.getDrafts.drafts;
    if (idType === 'WORKSPACE_ID' && drafts !== null && drafts !== void 0 && drafts.length) {
      setLocalDraft(drafts[0]);
    } else {
      setLocalDraft(drafts === null || drafts === void 0 ? void 0 : drafts.find(d => d.genericChannelId === genericChannelId));
    }
  }, [currentDrafts, genericChannelId, idType]);
  const clear = useCallback(() => {
    debouncedSave.cancel();
    if (genericChannelId) {
      deleteDraft({
        variables: {
          idType,
          id: idNumber,
          genericChannelId,
          timestamp: Date.now()
        }
      }).catch(error => {
        reportError({
          error: new Error('Error deleting draft'),
          extra: {
            error
          }
        });
      });
      setLocalDraft(undefined);
      onDraftChange === null || onDraftChange === void 0 || onDraftChange({
        text: ''
      });
    }
  }, [debouncedSave, deleteDraft, genericChannelId, idNumber, idType, onDraftChange]);
  const deserializedDraft = useMemo(() => localDraft && localDraft.jsonBlob ? deserializeCommonReplyDraft(localDraft.jsonBlob) : null, [localDraft]);
  return [deserializedDraft, debouncedSave, clear, !!(currentDrafts !== null && currentDrafts !== void 0 && currentDrafts.getDrafts.drafts), draftSaveFailed];
};