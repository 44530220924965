import { getData } from 'conversations-async-data/async-data/operators/getters';
import { useChannelConfigurations } from 'conversations-thread-data/channel-configuration/public/hooks';
import { getOriginalGenericChannelId } from 'conversations-thread-data/thread-details/public/operators';
import { getAvailableEditorConfigs } from 'conversations-thread-view/tabbed-editors/operators/getAvailableEditorConfigs';
import { useThreadDetails } from 'conversations-thread-view/thread-details-feature/public/hooks/useThreadDetails';
import { useEffect } from 'react';
import { getTrackingContext, setTracker } from '../clients/usageTracker';

// conflicting types between conversations-inbox-ui and usage-tracker-core
// this could be either:
// AsyncTracker - https://git.hubteam.com/HubSpot/conversations-inbox/blob/334be105eeb2f72773a42b0217832b90dc03d974/svh-workspace-ui-lib/static/js/usage-tracking/_internal/clients/usageTracker.ts#L26
// LockedTrackerInstance<TrackerConfig> - https://git.hubteam.com/HubSpot/usage-tracker-js/blob/49884c17b6e375ffabd192065f810c64d94e0a66/usage-tracker-core/static-1.4822/js/trackerTypes.ts#L73
export function useComposerTrackingContext({
  tracker,
  threadId,
  ticketCount
}) {
  const isThreadIdNaN = isNaN(Number(threadId));
  const {
    asyncThreadDetails
  } = useThreadDetails({
    threadId: isThreadIdNaN ? null : threadId,
    deferred: isThreadIdNaN
  });
  const threadDetails = getData(asyncThreadDetails);
  const originalGenericChannelId = getOriginalGenericChannelId(threadDetails);
  const {
    asyncChannelConfigurations
  } = useChannelConfigurations();
  const configurations = getData(asyncChannelConfigurations);
  const filteredConfigurations = getAvailableEditorConfigs({
    configurations,
    originalGenericChannelId,
    includeNonGenericChannels: false
  });
  const availableChannels = filteredConfigurations && filteredConfigurations.keySeq().toArray();

  // Tracking Setup and Internal Application events
  useEffect(() => {
    const trackingContext = getTrackingContext();
    setTracker(tracker, Object.assign({}, trackingContext, {
      availableChannels,
      threadId,
      ticketCount,
      originalGenericChannelId
    }));
  }, [availableChannels, tracker, threadId, originalGenericChannelId, ticketCount]);
}