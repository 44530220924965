import UILink from 'UIComponents/link/UILink';
import styled from 'styled-components';
import { MAGENTA, BLACK } from '../../styles/colors';
const AILink = styled(UILink).attrs({
  iconProps: {
    style: {
      color: BLACK['NEUTRAL']
    }
  }
}).withConfig({
  displayName: "AILink",
  componentId: "z279kw-0"
})(["color:", " !important;&:hover{color:", " !important;}&:active{color:", " !important;}"], MAGENTA[400], MAGENTA[500], MAGENTA[600]);
export default AILink;