import { gate } from 'hub-http/gates';

// CRM iframe timeline with popover sync.
export const CALLING_CRM_TIMELINE_POPOVER = 'Calling:CRM:TimelinePopover';
export const CALLING_WIDGET_LOADER = 'Calling:WidgetLoader';
export const CALLING_WIDGET_CONFIGURATION = 'Calling:WidgetConfiguration';
export const CALLING_INBOUND_LOADER = 'Calling:InboundLoader';
export const CALLING_MIGRATE_CALL_PREVIEW_SIDEBAR = 'Calling:PostCall:MigrateCallPreviewSidebar';
export const CALLING_PREVIEW_PANEL_CALLBACK = 'Calling:PreviewPanelCallback';
export const CALLING_EXTENSIONS_INBOUND_CALLING = 'Calling:ExtensionsInboundCalling';
export const CALLING_THIRD_PARTY_CHANNELS = 'Calling:HelpDesk:ThirdPartyChannels';
export const CALLING_PLACE_CALLER_ON_HOLD = 'Calling:PlaceCallerOnHold';
export const CALLING_NEXT_BEST_ACTIONS = 'Calling:Rep:NextBestActions';
export const CALLING_TRANSFER_CALL = 'Calling:TransferCall';
export const MOVE_INBOX_TO_CALLTAB = 'Calling:Rep:MoveInboxToCallTab';
export const CALLING_LIVE_RECOMMENDATIONS = 'Calling:Platform:InCallReplyRecommendation';
export const CALLING_LIVE_TRANSCRIPTION_COPILOT_INTEGRATION = 'Calling:Platform:InCallChatspot';
export const CALLING_COPILOT_PREVIEWS = 'Calling:Rep:CopilotPreviewSummaries';
export const CHATSPOT_WIDGET_BETA = 'Chatspot:Widget:Beta';
export const CALLING_PROVIDER_SWITCHING = gate('Calling:ProviderSwitching');
export const CALLING_EXTENSIONS_INBOUND_CALLING_ROLL_OUT = gate('Calling:ExtensionsInboundCallingRollOut');
export const CALLING_GATES = [CALLING_COPILOT_PREVIEWS, CALLING_CRM_TIMELINE_POPOVER, CALLING_EXTENSIONS_INBOUND_CALLING, CALLING_INBOUND_LOADER, CALLING_LIVE_RECOMMENDATIONS, CALLING_LIVE_TRANSCRIPTION_COPILOT_INTEGRATION, CALLING_NEXT_BEST_ACTIONS, CALLING_PLACE_CALLER_ON_HOLD, CALLING_PROVIDER_SWITCHING, CALLING_THIRD_PARTY_CHANNELS, CALLING_TRANSFER_CALL, CALLING_WIDGET_CONFIGURATION, CALLING_WIDGET_LOADER, CHATSPOT_WIDGET_BETA, CALLING_EXTENSIONS_INBOUND_CALLING_ROLL_OUT];
export const CALLING_REMOTE_GATES = [CALLING_EXTENSIONS_INBOUND_CALLING, CALLING_LIVE_RECOMMENDATIONS, CALLING_LIVE_TRANSCRIPTION_COPILOT_INTEGRATION, CALLING_PLACE_CALLER_ON_HOLD, CALLING_PREVIEW_PANEL_CALLBACK, CALLING_PROVIDER_SWITCHING, CALLING_THIRD_PARTY_CHANNELS, CALLING_TRANSFER_CALL, CALLING_EXTENSIONS_INBOUND_CALLING_ROLL_OUT];