import { createSlice } from '@reduxjs/toolkit';
import { getPrefetchState } from 'find-and-filter-data/prefetch/prefetchState';
import { bulkUpdateTickets } from 'find-and-filter-data/realtime-updates/public';
import memoize from 'hs-lodash/memoize';
import omit from 'hs-lodash/omit';
import { Map as ImmutableMap } from 'immutable';
import Raven from 'raven-js';
import { AsyncStatus } from '../../../common/public';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from '../../../search-and-filter-data/protected';
import { fetchUpdatedViews } from '../../../views-data/protected';
import { mergeIncomingViewMembersPage } from '../common/fetchViewMembersHelpers';
import { viewMemberSeen, viewMemberUnread } from '../common/sharedViewMemberActions';
import { defaultSortState } from '../utilities/persistSort';
import { buildHelpDeskViewMembersPage, buildSearchHelpDeskViewMembersPage } from '../view-member-page/buildHelpDeskViewMembersPage';
import { applyHelpDeskBulkUpdateMessage } from './applyHelpDeskViewUpdateMessage';
import { DEFAULT_LIMIT, isGetSearchMembersResponse } from './fetchHelpDeskViewMembersService';
import { bulkOptimisticUpdate, fetchHelpDeskViewMembers, fetchSearchViewMembers, optimisticUpdate, prefetchViewMembersFulfilled, updateViewTableCount as updateViewTableCountAction } from './helpDeskViewMemberActions';
import { sortHelpDeskViewMembers } from './sortHelpDeskViewMembers';

/**
 * @description Takes a list of sorted members and does 2 things:
 *
 * 1. Discards members that are sorted after the offset member. This prevents
 *    "gaps" when fetching additional pages.
 * 2. Caps the number of members to max(DEFAULT_LIMIT, previousCount) to avoid
 *    state growing unbounded via realtime additions.
 */
function limitMembersOnRealtimeAddition({
  hasMore,
  members,
  offsetMember,
  previousCount,
  sortState
}) {
  // When there are additional pages of results (hasMore === true), do not allow
  // members to be appended in realtime. We receive realtime events for the
  // entire view, so there may be a "gap" between the last page member fetched
  // via a search and a realtime addition. Since we fetch the next page using
  // the last viewMemberId as the offset, allowing this would result in an
  // incorrect page offset.
  if (hasMore) {
    const comparePosition = sortHelpDeskViewMembers(sortState !== null && sortState !== void 0 ? sortState : defaultSortState);

    // Slice off any members that are sorted after the offset member.
    members = members.takeWhile(member => comparePosition(offsetMember, member) >= 0);
  }

  // Cap the number of members to max(DEFAULT_LIMIT, previousCount) to avoid
  // state growing unbounded via realtime additions. Once the limit is reached,
  // inserting members into the list will result in an equal number of members
  // falling off the end.
  const limit = Math.max(DEFAULT_LIMIT, previousCount);
  return members.slice(0, limit);
}
const updateTickets = ({
  data,
  message,
  viewId,
  sortState
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    const hasMore = page.hasMore;
    const previousCount = page.indexedMembers.count();
    const offsetMember = page.indexedMembers.last();
    const updatedMembers = applyHelpDeskBulkUpdateMessage(page.indexedMembers, message);
    const sortedMembers = sortState ? updatedMembers.sort(sortHelpDeskViewMembers(sortState)) : updatedMembers;
    const nextCount = sortedMembers.count();
    return page.set('indexedMembers', limitMembersOnRealtimeAddition({
      hasMore,
      members: sortedMembers,
      offsetMember,
      previousCount,
      sortState
    }))
    // When members are added in realtime and the resulting count is greater
    // than DEFAULT_LIMIT, then we are guaranteed to have more pages of
    // results. This is true because of the way we slice off members to keep
    // state from ballooning. This logic ensures that the user can continue
    // to paginate after realtime updates result in a new page of results.
    .set('hasMore', hasMore || nextCount > previousCount && nextCount > DEFAULT_LIMIT);
  });
};
const updateViewTableCount = ({
  data,
  viewId,
  tableCount
}) => {
  return data.update(viewId, page => {
    if (!page) return page;
    const updatedPage = page.set('totalCount', typeof tableCount === 'number' ? tableCount : page.get('totalCount'));
    return updatedPage;
  });
};
const initialState = {
  data: ImmutableMap(),
  status: AsyncStatus.UNINITIALIZED
};
const getInitialPrefetchState = memoize(prefetchState => {
  if (!prefetchState) {
    return initialState;
  }
  const {
    status,
    searchParams,
    viewMembersResponse,
    viewId
  } = prefetchState.viewMembers;
  return {
    status,
    data: status === AsyncStatus.SUCCEEDED && viewId && viewMembersResponse ? ImmutableMap({
      [viewId]: buildHelpDeskViewMembersPage(viewMembersResponse)
    }) : ImmutableMap(),
    lastResolvedSearchParams: status === AsyncStatus.SUCCEEDED || status === AsyncStatus.FAILED ? searchParams : null
  };
}, prefetchState => prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.prefetchId);
export const helpDeskViewMembersSlice = createSlice({
  name: 'helpDeskViewMembers',
  initialState() {
    const prefetchState = getPrefetchState();
    return getInitialPrefetchState(prefetchState);
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(optimisticUpdate, (state, action) => {
      var _state$data$get;
      const {
        payload
      } = action;
      const viewMember = (_state$data$get = state.data.get(payload.viewId)) === null || _state$data$get === void 0 ? void 0 : _state$data$get.indexedMembers.get(payload.id);
      const updatedViewMember = payload.updater(viewMember);
      if (updatedViewMember) {
        state.data = state.data.updateIn([payload.viewId, 'indexedMembers', payload.id], value => value ? Object.assign({}, value, updatedViewMember) : value);
      }
    }).addCase(viewMemberSeen, (state, action) => {
      const {
        payload
      } = action;
      const {
        agentId,
        customViewIds,
        objectId
      } = payload;
      const allViews = [...customViewIds, DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER];

      //Update the ViewMember record in cached custom views.
      for (const viewId of allViews) {
        state.data = state.data.updateIn([`${viewId}`, 'indexedMembers', objectId], viewMember => {
          if (!viewMember) return viewMember;
          const seenByAgentIds = new Set(viewMember.seenByAgentIds);
          seenByAgentIds.add(agentId);
          return Object.assign({}, viewMember, {
            seenByAgentIds: Array.from(seenByAgentIds)
          });
        });
      }
    }).addCase(viewMemberUnread, (state, action) => {
      const {
        payload
      } = action;
      const {
        agentId,
        objectId,
        customViewIds
      } = payload;

      //Update the ViewMember record in cached custom views.
      for (const viewId of customViewIds) {
        state.data = state.data.updateIn([`${viewId}`, 'indexedMembers', objectId], viewMember => {
          if (!viewMember) return viewMember;
          const seenByAgentIds = new Set(viewMember.seenByAgentIds);
          seenByAgentIds.delete(agentId);
          return Object.assign({}, viewMember, {
            seenByAgentIds: Array.from(seenByAgentIds)
          });
        });
      }
    }).addCase(fetchHelpDeskViewMembers.pending, (state, {
      meta
    }) => {
      const disabledLoad = meta.arg && meta.arg.options && meta.arg.options.disabledLoad;
      if (!disabledLoad) {
        const hasOffset = meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0;
        if (hasOffset) {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        } else {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
        }
      }
      state.pendingSearchParams = omit(meta.arg, 'options');
    }).addCase(fetchHelpDeskViewMembers.fulfilled, (state, {
      meta,
      payload
    }) => {
      var _meta$arg;
      if (isGetSearchMembersResponse(payload)) {
        if (!payload.viewMemberPagedResult) {
          state.status = AsyncStatus.FAILED;
        }
      } else if (!payload.results) {
        state.status = AsyncStatus.FAILED;
      }
      if (state.status === AsyncStatus.FAILED) {
        Raven.captureMessage(`fetchHelpDeskViewMembers.fulfilled payload did not include viewMemberPagedResult`, {
          extra: {
            payload
          }
        });
        return;
      }
      if ((_meta$arg = meta.arg) !== null && _meta$arg !== void 0 && _meta$arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data.get(meta.arg.viewId);
        const newPage = buildHelpDeskViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(meta.arg.viewId, updatedPage);
      } else {
        state.data = ImmutableMap({
          [meta.arg.viewId]: buildHelpDeskViewMembersPage(payload)
        });
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchHelpDeskViewMembers.rejected, (state, {
      meta
    }) => {
      state.status = AsyncStatus.FAILED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchSearchViewMembers.pending, (state, {
      meta
    }) => {
      const disabledLoad = meta.arg && meta.arg.options && meta.arg.options.disabledLoad;
      if (!disabledLoad) {
        const hasOffset = meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0;
        if (hasOffset) {
          state.status = AsyncStatus.PAGINATED_FETCH_LOADING;
        } else {
          state.status = AsyncStatus.INITIAL_FETCH_LOADING;
        }
      }
      state.pendingSearchParams = omit(meta.arg, 'options');
    }).addCase(fetchSearchViewMembers.fulfilled, (state, {
      meta,
      payload
    }) => {
      if (!payload.searchResults) {
        state.status = AsyncStatus.FAILED;
        Raven.captureMessage(`fetchSearchViewMembers.fulfilled payload did not include searchResults`, {
          extra: {
            payload
          }
        });
        return;
      }
      if (meta.arg && meta.arg.offsetId && meta.arg.offsetId > 0) {
        const oldPage = state.data.get(DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER);
        const newPage = buildSearchHelpDeskViewMembersPage(payload);
        const updatedPage = mergeIncomingViewMembersPage({
          oldPage,
          newPage,
          totalCount: payload.totalCount
        });
        if (updatedPage) state.data = state.data.set(DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER, updatedPage);
      } else {
        state.data = ImmutableMap({
          [DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER]: buildSearchHelpDeskViewMembersPage(payload)
        });
      }
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(fetchSearchViewMembers.rejected, (state, {
      meta
    }) => {
      state.status = AsyncStatus.FAILED;
      state.pendingSearchParams = undefined;
      state.lastResolvedSearchParams = meta.arg;
    }).addCase(prefetchViewMembersFulfilled, (state, action) => {
      state.status = action.payload.status;
      state.lastResolvedSearchParams = action.payload.searchParams;
      state.pendingSearchParams = undefined;
      const {
        viewId,
        viewMembersResponse
      } = action.payload;
      if (viewId && viewMembersResponse) {
        state.data = ImmutableMap({
          [viewId]: buildHelpDeskViewMembersPage(viewMembersResponse)
        });
      }
    }).addCase(bulkUpdateTickets, (state, action) => {
      const {
        added,
        customViewId,
        updated,
        removed,
        sortState
      } = action.payload;
      state.data = updateTickets({
        data: state.data,
        message: {
          added,
          updated,
          removed: removed || []
        },
        viewId: customViewId ? String(customViewId) : state.data.keySeq().first(),
        sortState
      });
    }).addCase(bulkOptimisticUpdate, (state, action) => {
      var _state$data$get2;
      const {
        ids,
        updater,
        sortState,
        viewId
      } = action.payload;
      const resolvedViewId = viewId || state.data.keySeq().first();
      const updated = [];
      const currentViewMembers = (_state$data$get2 = state.data.get(resolvedViewId)) === null || _state$data$get2 === void 0 ? void 0 : _state$data$get2.indexedMembers;
      for (const id of ids) {
        const viewMember = currentViewMembers === null || currentViewMembers === void 0 ? void 0 : currentViewMembers.get(id);
        const updatedViewMember = updater(viewMember);
        if (updatedViewMember) {
          updated.push(updatedViewMember);
        }
      }
      if (updated.length) {
        state.data = updateTickets({
          data: state.data,
          message: {
            added: [],
            updated,
            removed: []
          },
          viewId: resolvedViewId,
          sortState
        });
      }
    }).addCase(fetchUpdatedViews.fulfilled, (state, action) => {
      const {
        currentViewTableCount
      } = action.payload;
      const {
        currentCustomViewId
      } = action.meta.arg;
      state.data = updateViewTableCount({
        data: state.data,
        viewId: String(currentCustomViewId),
        tableCount: currentViewTableCount
      });
    }).addCase(updateViewTableCountAction, (state, action) => {
      const {
        viewId,
        currentViewTableCount
      } = action.payload;
      state.data = updateViewTableCount({
        data: state.data,
        viewId,
        tableCount: currentViewTableCount
      });
    });
  }
});