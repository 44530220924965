import { getFullUrlWithCurrentSubDomain } from 'hubspot-url-utils';
import PortalIdParser from 'PortalIdParser';
let appUrls;
const getAppUrls = () => {
  if (appUrls) return appUrls;
  const baseUrl = getFullUrlWithCurrentSubDomain();
  const portalId = PortalIdParser.get();
  appUrls = {
    accountAndBillingUrl: `${baseUrl}/account-and-billing/${portalId}/overview`,
    callAnalyticsUrl: `${baseUrl}/call-analytics/${portalId}`,
    callIndexUrl: `${baseUrl}/contacts/${portalId}/objects/0-48/views/all/list`,
    callingConfigurationSettingsUrl: `${baseUrl}/settings/${portalId}/calling/callconfigurations`,
    callingIvrSettingsUrl: `${baseUrl}/settings/${portalId}/calling/ivr`,
    callingMainSettingsUrl: `${baseUrl}/settings/${portalId}/calling/numbers`,
    callingToolSettingsUrl: `${baseUrl}/settings/${portalId}/calling`,
    callingUserPreferencesUrl: `${baseUrl}/settings/${portalId}/user-preferences/calling`,
    calendarUserPreferencesUrl: `${baseUrl}/settings/${portalId}/user-preferences/calendar`,
    helpDeskSettingsUrl: `${baseUrl}/helpdesk-settings/${portalId}/inboxes`,
    inboxSettingsUrl: `${baseUrl}/live-messages-settings/${portalId}/inboxes`,
    individualUserSettingsUrl: `${baseUrl}/settings/${portalId}/users/user/`,
    // append userId
    integrationsSettingsUrl: `${baseUrl}/integrations-settings/${portalId}/installed`,
    integrationsUrl: `${baseUrl}/ecosystem/${portalId}/marketplace/apps/calling`,
    marketplace: `${baseUrl}/ecosystem/${portalId}/marketplace/apps/calling`,
    paidUsersManagementUrl: `${baseUrl}/settings/${portalId}/users`,
    recordingReviewUrl: `${baseUrl}/calls/${portalId}/review`,
    // append engagementId
    supportUrl: `${baseUrl}/support/${portalId}/open`,
    usersAndTeamsSettingsUrl: `${baseUrl}/settings/${portalId}/users`,
    accountVerificationUrl: `${baseUrl}/account-verification/${portalId}?source=CALLING`,
    transcriptionAnalysisSettingsUrl: `${baseUrl}/settings/${portalId}/calling/transcriptionanalysis`,
    playListsUrl: `${baseUrl}/playlists/${portalId}`
  };
  return appUrls;
};
export const getAppUrl = (key, subpath) => `${getAppUrls()[key]}${subpath ? `/${subpath}` : ''}`;
export default getAppUrls;