import { LINE_ITEM_TYPE_ID, PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { isPricingModelTiered } from 'customer-data-objects/product/Pricing';
import { isMultiCurrencyPrice } from 'customer-data-objects/property/PropertyIdentifier';
import { useAdditionalPropertyValues } from '../hooks/useAdditionalPropertyValues';
export const useIsTieredPricingProperty = ({
  objectTypeId,
  property
}) => {
  const subjectIsProductOrLineItem = objectTypeId === PRODUCT_TYPE_ID || objectTypeId === LINE_ITEM_TYPE_ID;
  const {
    data: additionalValues
  } = useAdditionalPropertyValues({
    hs_pricing_model: 'hs_pricing_model'
  });
  const isPriceProperty = isMultiCurrencyPrice(property) || property.name === 'price';
  if (!subjectIsProductOrLineItem || !isPriceProperty) {
    return false;
  }
  const isTieredPricingProperty = isPricingModelTiered(additionalValues === null || additionalValues === void 0 ? void 0 : additionalValues.hs_pricing_model);
  return isTieredPricingProperty;
};